<template>
  <v-avatar :size="size" color="primary">
    <v-img
      v-if="user.photo"
      :lazy-src="require('@/assets/marvin.png')"
      aspect-ratio="1"
      class="grey lighten-2"
      :src="user.photo"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <span v-else-if="user.Login" class="white--text">
      {{ user.Login.replace(/[0-9]/g, "").toUpperCase() }}
    </span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    user: { type: Object, required: true },
    size: { type: Number, default: 200 }
  }
};
</script>
